
import Cookies from 'js-cookie'

import Notification from '@/components/NotificationSuccess.vue'
import api from '@/store/api'

export default {
  components: { Notification },
  data() {
    return {
      handle: '',
      password: '',
      error: '',
      tries: 0,
      flash: false,
      passwordReset: false
    }
  },
  mounted() {
    this.passwordReset = this.$route.fullPath.includes('reset-success')
  },
  methods: {
    loginAction: function () {
      const apiEndpoint = `tokens`
      api
        .call(
          'POST',
          apiEndpoint,
          {
            handle: this.handle,
            password: this.password
          },
          false
        )
        .then(response => {
          this.authenticate(response.data.token)
        })
        .catch(() => {
          this.error = 'Incorrect handle or password'
          this.tries++
          if (this.tries > 1) {
            this.flash = true
            setTimeout(() => {
              this.flash = false
            }, 200)
          }
        })
    },
    signUpClicked() {
      window.location = 'https://www.bottle.com/book-a-demo'
    },
    authenticate: function (token) {
      Cookies.set('auth_token', token, { expires: 6 * 31 })
      this.$store.dispatch('getLoggedInUser')
      window.pusherInit()
      this.$router.push('/')
    }
  }
}
